import { Injectable } from '@angular/core';
import { defer, from, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PrinterService {
    // TODO: use actual print status enum
    public status = 0;

    public printJob$(): Observable<PrintJobPayload> {
        const emitter = new Subject<string>();

        window.api.registerPrintJobResponder(({ payload }: any) => {
            emitter.next(payload);
        });

        return emitter;
    }

    public setPrinterStatus$(status: PrinterStatus): Observable<boolean> {
        return defer(() => from(window.api.setPrinterStatus({ payload: status }))).pipe(
            tap(({ state }) => {
                this.status = state;
            }),
            map(({ success }) => success)
        );
    }
}
