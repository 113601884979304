import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    public openReleaseFolder(): void {
        window.api.openDialog('ReleaseFolder');
    }
}
