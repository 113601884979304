import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PdfPrinterService {
    private iFrameEl: HTMLIFrameElement;
    private renderer: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.createIFrameEl();
    }

    /**
     * Initializes the flow to print a pdf when a pdf is received
     * over the ipc channel.
     *
     * A delay is necessary to ensure the pdf is loaded in the iframe.
     */
    public initializePrintOnPdfReceive$(): Observable<void> {
        return this.printPdfJob$().pipe(
            map((blobUrl) => this.embedPdfBlobUrl(blobUrl)),
            delay(50),
            tap(() => this.iFrameEl.contentWindow.print())
        );
    }

    /**
     * @returns An Observable that resolves to a blob url of the pdf.
     */
    private printPdfJob$(): Subject<string> {
        const emitter = new Subject<string>();

        window.api.registerPrintPdfResponder(async ({ payload }) => {
            const { base64 } = payload;
            const blobUrl = await this.createBlobUrl(base64);
            emitter.next(blobUrl);
        });

        return emitter;
    }

    /**
     * Creates a hidden iframe element and appends it to the document body.
     *
     * This is necessary to print the pdf.
     */
    private createIFrameEl(): void {
        if (this.iFrameEl) return;

        this.iFrameEl = this.renderer.createElement('iframe');
        this.renderer.setProperty(this.iFrameEl, 'style', 'display: none;');
        this.renderer.appendChild(this.document.body, this.iFrameEl);
    }

    /**
     * Creates a blob url from a base64 encoded pdf.
     *
     * @param base64 base64 encoded pdf.
     * @returns An Observable that resolves to a blob url of the pdf.
     */
    private async createBlobUrl(base64: string): Promise<string> {
        const base64Response = await fetch(`data:application/pdf;base64,${base64}`);
        const blob = await base64Response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        return blobUrl;
    }

    /**
     * @param blobUrl The blob url of the pdf to embed in the iframe.
     */
    private embedPdfBlobUrl(blobUrl): void {
        this.renderer.setProperty(this.iFrameEl, 'src', blobUrl);
    }
}
