import { Injectable } from '@angular/core';
import { ReleaseTypesApiFacade } from '@kno2/shared/data-access/api-kno2fy-main';
import { defer, from, Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReleaseMonitorService {
    public releaseTypes$ = this.releaseTypesApi.getReleaseTypes$().pipe(
        filter(({ isFetching }) => !isFetching),
        map(({ data }) => data.releaseTypes)
    );
    constructor(private releaseTypesApi: ReleaseTypesApiFacade) {}

    public startMonitor$(forceReleaseTypesRefresh = false): Observable<boolean> {
        if (forceReleaseTypesRefresh) this.releaseTypesApi.forceReleaseTypesInvalidation();

        return this.releaseTypes$.pipe(
            switchMap((releaseTypes) => defer(() => from(window.api.setReleaseTypes({ payload: releaseTypes })))),
            map(({ success }: IpcResponse) => <boolean>success)
        );
    }

    public releaseJob$(): Observable<ReleaseJobPayload> {
        const emitter = new Subject<string>();

        window.api.registerReleaseJobResponder(({ payload }: any) => {
            emitter.next(payload);
        });

        return emitter;
    }
}
