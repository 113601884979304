import { startWith, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { AuthSliceFacade } from '@kno2/shared/data-access/+store';
import { from, Observable, Subject } from 'rxjs';
import { AuthServiceLike } from '@kno2/shared/util/common';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements AuthServiceLike {
    constructor(private authSlice: AuthSliceFacade) {}

    public accessToken$(): Observable<string> {
        const emitter = new Subject<string>();

        window.api.registerTokenResponder(({ payload }: any) => {
            emitter.next(payload);
        });

        return from(window.api.requestAuthToken()).pipe(
            switchMap(({ payload }: IpcResponse) => emitter.asObservable().pipe(startWith(<string>payload)))
        );
    }

    public login(options?: RedirectLoginOptions<any>): Observable<void> {
        throw new Error('Method not implemented.');
    }
    public logout(): void {
        window.api.logout();
    }
    public scheduleRenewToken(): Observable<void> {
        throw new Error('Method not implemented.');
    }
    public renewToken(forceNewToken?: boolean): Observable<string> {
        throw new Error('Method not implemented.');
    }
}
